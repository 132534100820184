<template>
    <PokemonDetails />
  </template>
  
  <script>
    import PokemonDetails from '../components/PokemonDetails'
  
    export default {
      name: 'Details-View',
  
      components: {
        PokemonDetails,
      },
    }
  </script>
  