<template>
    <PokemonDisplay />
  </template>
  
  <script>
    import PokemonDisplay from '../components/PokemonDisplay'
  
    export default {
      name: 'All-Generations',
  
      components: {
        PokemonDisplay,
      },
    }
  </script>
  